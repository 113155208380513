.container-about{
  position: relative;
  display: flex;
  flex-direction: column;
  background: $lightGrey;
  width: 94vw;
  margin-top: 110px;
  overflow-x: hidden;

  .c-infos{
    color: $bg;

    h2{
      font-family: $primaryFont;
      font-size: 200px;
      //@include font(200, 200);
      font-style: normal;
      font-weight: 300;
      padding-top: 100px;
      padding-left: 5vw;
      letter-spacing: -4.6px;
      @include mobile(){
        @include font(70, 84);
        padding-top: 36px;
      }
      @include min-width(1979){
        font-size: 11vw;
      }

      .o-hidden{
        display: block;
        line-height: 30vh;
        @include mobile(){
          line-height: 170px;
        }
        &.o-2{
          transform: translateX(34vw);
          margin-top: -100px;
          @include mobile(){
            transform: translateX(10vw);
          }
        }
        &.o-3{
          transform: translateX(10vw);
          margin-top: -100px;
          @include mobile(){
            transform: translateX(-10vw);
          }
        }
      }
      div{
        width: 100%;
        position: relative;
        display: inline-block;
        margin: -24px 0;
      }
      .italic{
        font-style: italic;
      }
    }
    &_desc{
      position: relative;
      display: flex;
      @include font(18, 32);
      font-family: $secondaryFont;
      font-weight: 500;
      margin-top: 150px;
      @include mobile(){
        flex-direction: column;
        @include font(16, 32);
        padding: 0 30px;
        max-width: 100%;
        margin-bottom: calc(var(--vh, 1vh) * 15);
      }

      .c-left{
        width: 100%;
        @include max-width(1919){
          margin-left: 23vw;
          max-width: 18vw;
        }
        @include min-width(1919){
          margin-left: 26vw;
          max-width: 14vw;
        }
        @include mobile(){
          margin-left: 0;
          max-width: 100%;
        }
      }
      .c-right{
        width: 100%;
        @include max-width(1919){
          margin-top: 45vh;
          margin-top: calc(var(--vh, 1vh) * 45);
          margin-left: 14vw;
          max-width: 17vw;
        }
        @include min-width(1919){
          margin-top: 25vh;
          margin-top: calc(var(--vh, 1vh) * 25);
          margin-left: 12vw;
          max-width: 14vw;
        }
        @include mobile(){
          margin-left: 0;
          margin-right: 0;
          max-width: 100%;
        }
      }
      .bg-a{
        position: absolute;
        width: 100%;
        height: 100%;
        font-family: $primaryFont;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 40vw;
        color: $white;
        z-index: -1;
        @include mobile(){
          width: 75vw;
          z-index: 1;
        }
      }
    }
  }
  .grid-logos{
    margin-top: 25vh;
    margin-top: calc(var(--vh, 1vh) * 25);

    img{
      mix-blend-mode: multiply;
      opacity: 0.5;
    }
  }
  .c-direction{
    width: 100%;
    height: 100px;
    @include mobile(){
      margin-top: 100px;
    }

    &-block{
      font-family: $primaryFont;
      @include font(22, 26);
      font-weight: 700;
      text-transform: uppercase;
      transform-origin: center;
      &:first-child{
        transform: rotate(10deg);
        background-color: #fff;
        margin-left: -10vw;

        span{
          background-color: #fff;
        }
      }
      &:last-child{
        transform: rotate(-5deg);
        background-color: #E0E0E0;

        span{
          background-color: #E0E0E0;
        }
      }
    }

    span{
      display: block;
      color: #000;
      white-space: nowrap;
      padding: 40px 20px;
      width: 140%;
    }
  }
  .insta{
    position: relative;
    color: $black;
    margin-top: 20vh;
    margin-top: calc(var(--vh, 1vh) * 20);
    margin-bottom: 10vh;
    margin-bottom: calc(var(--vh, 1vh) * 10);

    h4{
      display: block;
      font-family: $primaryFont;
      font-weight: 100;
      font-size: 22vw;
      margin-left: -30px;
      font-style: normal;
      white-space: nowrap;
      @include mobile(){
        margin-left: 0;
      }
      span{
        font-style: italic;
      }
    }

    .h-01, .h-02{
      position: absolute;
      display: block;
      @include font(22, 28);
      font-family: $secondaryFont;
      font-weight: 300;
    }
    .h-01{
      top: 10vh;
      top: calc(var(--vh, 1vh) * 10);
      left: 10vw;
      @include mobile(){
        top: -20px;
      }
    }
    .h-02{
      right: 10vw;
      @include mobile(){
        margin-top: 20px;
      }
    }

    &.c-trail{
      overflow: unset;
    }
  }
  .awards{
    display: flex;
    margin-top: 25vh;
    margin-top: calc(var(--vh, 1vh) * 25);
    @include mobile(){
      flex-direction: column;
    }

    &-container{
     width: 40%; 
     color: #0f0f0f;
     margin-left: 100px;
     @include mobile(){
       width: 100%;
       margin-left: 0;
       padding: 0 40px;
     }

     h5{
       display: block;
       @include font(10, 150);
       font-family: $secondaryFont;
       letter-spacing: 16px;
       text-transform: uppercase;
       font-weight: 700;
     }
     .award-item{
       margin-bottom: 50px;
     }
     .a-title{
       display: block;
       @include font(22, 28);
       font-family: $primaryFont;
       margin-bottom: 10px;
     }
     .a-desc{
       display: block;
       @include font(16, 22);
       font-family: $secondaryFont;
       color: #6e6e6e;
     }
    }
  }
  .et-merce{
    position: relative;
    height: 120vh;
    height: calc(var(--vh, 1vh) * 120);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .calling-card{
      position: absolute;
      max-width: 17.361vw;
      @include mobile(){max-width: 55vw; display: none;}
      &.s-mobile{@include mobile(){display: block;}}
      //&:first-child{left: -10vw;top: 10vh; top: calc(var(--vh, 1vh) * 10); transform: rotate(60deg);}
      &:nth-child(2){right: 10vw;top: 15vh; top: calc(var(--vh, 1vh) * 15); transform: rotate(40deg);}
      &:nth-child(3){left: 45vw;top: 10vh; top: calc(var(--vh, 1vh) * 10); transform: rotate(20deg);}
      &:nth-child(4){right: 40vw;top: 15vh; top: calc(var(--vh, 1vh) * 15); transform: rotate(-10deg);}
      &:nth-child(5){left: 20vw;top: 17.5vh; top: calc(var(--vh, 1vh) * 17.5); transform: rotate(30deg);}
      &:nth-child(6){left: 40vw;top: 25vh; top: calc(var(--vh, 1vh) * 25); transform: rotate(-20deg);}
      &:nth-child(7){left: -4vw;top: 40vh; top: calc(var(--vh, 1vh) * 40); transform: rotate(-10deg);}
      &:nth-child(8){right: 45vw;top: 50vh; top: calc(var(--vh, 1vh) * 50); transform: rotate(-40deg);}
      &:nth-child(9){right: 30vw;bottom: 30vh; bottom: calc(var(--vh, 1vh) * 30); transform: rotate(10deg);}
      &:nth-child(10){right: 10vw;bottom: 40vh; bottom: calc(var(--vh, 1vh) * 40); transform: rotate(60deg);}
    }
    .title-code{
      width: 100%;
      margin-top: -10vh;
      margin-top: calc(var(--vh, 1vh) * -10);
    }
    h3{
      display: flex;
      font-size: 10.069vw;
      font-family: $primaryFont;
      font-style: italic;
      color: #000;
      &:first-child{margin-left: 12.083vw;}
      &:last-child{margin-left: 25.694vw;}
    }
    a{
      display: flex;
      align-items: center;
      width: fit-content;
      white-space: nowrap;
      color: #000;
      font-family: $primaryFont;
      margin-top: 30px;
      @include font(22, 26);
      z-index: -1;
      @include mobile(){z-index: 1;}
      img{
        height: 18px;
        margin-right: 14px;
      }
    }
  }
  .about-footer{
    display: flex;
    justify-content: space-between;
    color: #0f0f0f;
    margin-top: 35vh;
    margin-top: calc(var(--vh, 1vh) * 35);
    height: 20vh;
    @include mobile(){
      margin-top: 15vh;
      margin-top: calc(var(--vh, 1vh) * 15);
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: auto;
    }

    .f-left{
      display: flex;
      margin-left: 100px;
      @include mobile(){
        margin-left: 0;
        flex-direction: column;
        text-align: center;
      }

      a{
        display: block;
        font-family: $primaryFont;
        @include font(22, 28);
        margin-right: 3vw;
        height: fit-content;
        @include mobile(){
          margin-bottom: 24px;
        }
      }
    }
    .f-right{
      margin-right: 100px;
      white-space: nowrap;
      @include font(22, 28);
      font-family: $primaryFont;
      color: #0f0f0f;
      @include mobile(){
        margin-right: 0;
        margin-top: 40px;
        margin-bottom: 100px;
      }
      
      a{ display: flex; align-items: center; }
      img{ height: 18px; margin-right: 14px; }
    }
    svg{
      position: absolute;
      bottom: 40px;
      max-height: 55vh;
      max-height: calc(var(--vh, 1vh) * 55);
      z-index: -1;
      margin: 0 auto;
    }
  }
}

.a-layer{
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background: $bg;
  z-index: 99;
}

.aside-footer{
  position: absolute;
  top: 85vh;
  top: calc(var(--vh, 1vh) * 85);
  right: 0;
  display: flex;
  
  span{
    display: block;
    @include font(20, 28);
    font-family: $secondaryFont;
    font-weight: 400;
    color: #666666;
    @include mobile(){
      display: none;
    }
  }
}