.slider-home{
  position: absolute;
  top: 0;
  cursor: grab;
  overflow: hidden;
  width: 100%;
  height: 100%;
  @include mobile(){
    overflow-x: scroll;
  }
}

.container--active{
  cursor: grabbing;
}

.c-logo{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  img{
    max-width: 75vw;
  }
}

.slider-home .c-cover{
  @include mobile(){
    display: none;
  }
}

.c-mobile{
  display: none;
  opacity: 0;

  @include mobile(){
    position: relative;
    display: flex;
    align-items: center;
    overflow-x: scroll;
    width: auto;
    height: 100%;
    z-index: 99;
  }

  img{
    width: 80vw;
    height: auto;
    margin-left: 10vw;
  }
}