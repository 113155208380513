.container-project{
  position: relative;
  cursor: wait;
  @include mobile(){
    overflow-x: hidden;
  }

  .c-head{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: 100%;
    background: $bg;

    img{position: absolute;}
    .c-blister{
      width: 29vw;
      z-index: 99;
      @include mobile(){
        width: 88vw;
      }
    }
    .c-cover{
      width: 26vw;
      @include mobile(){
        width: 80vw;
      }
      //z-index: 98;
    }
    .d-image{
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
    }
    canvas{
      position: absolute;
      width: 100%;
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
      opacity: 1;
      z-index: 99;
    }
  }
  .c-layer{
    position: absolute;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    top: 0;
    transform-origin: bottom;
  }
  .l-item{
    position: absolute;
    top: 30vh;
    top: calc(var(--vh, 1vh) * 30);
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    z-index: 10;
    @include font(10, 16);
    font-family: $secondaryFont;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 16px;
    opacity: 0;
    @include mobile(){
      @include font(8, 16);
      letter-spacing: 10px;
    }
  }
  .t-head{
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    z-index: 10;

    h2{
      display: block;
      font-family: $primaryFont;
      font-weight: 300;
      line-height: 1.5;
      font-size: 16vw;
      white-space: nowrap;
      letter-spacing: -13px;
      @include mobile(){
        font-size: 90px;
        letter-spacing: 0;
      }

      span{
        position: relative;
        display: inline-block;
      }
      .italic{
        @include mobile(){
          letter-spacing: -10px;
        }
      }
      .small{
        @include font(20, 20);
        font-weight: 100;
        top: -12vh;
        top: calc(var(--vh, 1vh) * -12);
      }
    }
    .o-hidden{
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  .o-big{
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    img{ 
      max-width: 88.889vw;
      margin-top: -10vh;  
      margin-top: calc(var(--vh, 1vh) * -10);
    }
    .b-issue{
      display: flex;
      justify-content: space-between;
      max-width: 88.889vw;
      width: 100%;
      margin-top: 80px;
      @include mobile(){
        margin-top: 16px;
      }

      span{
        font-family: $primaryFont;
        color: $bg;
        font-weight: 500;
        @include font(20, 26);
      }
    }
  }
  .b-wrapper{
    display: flex;
    justify-content: center;

    .b-date{
      margin-top: 10vh;
      margin-top: calc(var(--vh, 1vh) * 10);
      width: 100%;
      @include font(16, 22);
      font-family: $secondaryFont;
      font-weight: 500;

      span{
        position: absolute;
      }
      .l-date{
        text-align: left;
        left: 2.5vw;
        @include mobile(){
          left: 5.5555vw;
        }
      }
      .r-date{
        text-align: right;
        right: 2.5vw;
        @include mobile(){
          right: 5.5555vw;
        }
      }
    }
  }
  .c-wrapper{
    margin-top: -20vh;
    margin-top: calc(var(--vh, 1vh) * -20);

    h3{
      display: block;
      font-size: 120px;
      font-family: $primaryFont;
      font-weight: 300;
      letter-spacing: -2.5px;
      @include mobile(){
        font-size: 10vw;
        margin-top: -30vh;
        margin-top: calc(var(--vh, 1vh) * -30);
      }
      .o-hidden{
        margin: -30px 0;
        div{
          line-height: 160px;
          transform: translateY(200px);          
          transition: transform 1s cubic-bezier(0.22, 0.61, 0.36, 1);
          @include mobile(){
            line-height: 75px;
          }
        }
      }
      span{
        font-style: italic;
      }
    }
    .author{
      display: block;
      margin-left: 5.5555vw;
      @include font(10, 150);
      font-family: $secondaryFont;
      letter-spacing: 16.5px;
      text-transform: uppercase;
      font-weight: 700;
      @include mobile(){
        @include font(10, 24);
        margin-top: 40px;
        letter-spacing: 6px;
      }
    }
  }
  /*.o-image_mask{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
  }*/
  .o-image{
    position: relative;
    overflow: hidden;
  }
  .list-photos{
    position: relative;
    z-index: 99;
    /* min-height: 300vh;
    min-height: calc(var(--vh, 1vh) * 300); */
    @include min-width(1919){
      /* min-height: 400vh;
      min-height: calc(var(--vh, 1vh) * 400); */
    }

    h3{
      position: absolute;
      display: block;
      font-family: $primaryFont;
      letter-spacing: -18px;
      margin-left: -50px;
      white-space: nowrap;
      padding-top: 15vh;
      padding-top: calc(var(--vh, 1vh) * 15);
      span{ font-style: italic; }
    }
    .grid-p{
      position: relative;
      padding: 0 5.556vw;
      @include mobile(){
        padding: 0 24px;
      }

      &_two{
        display: flex;
        flex-direction: row;
        &:first-child{
          margin-top: 50vh;
          margin-top: calc(var(--vh, 1vh) * 50);
          @include mobile(){
            margin-top: 200px;
          }
        }
        @include mobile(){
          flex-direction: column;
        }
      }
      &_two + .grid-p_two{
        @include mobile(){
          margin-top: 40px;
        }
      }
      .o-image{
        display: flex;
        flex-direction: column;
        width: calc(50vw - 5.556vw);
        height: fit-content;
        @include mobile(){
          width: 100%;
        }
        &:nth-child(even){
          margin-left: 5.556vw;
          margin-top: 80vh;
          margin-top: calc(var(--vh, 1vh) * 80);
          @include mobile(){
            margin-left: 0;
            margin-top: 40px;
          }
        }
      }
    }
  }
  #listPhotos, #listPhotos2, #listPhotos3, #listPhotos4{
    img{
      clip-path: inset(100% 0 0 0);
    }
  }
  .s-image{
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 100vh;
    margin-top: calc(var(--vh, 1vh) * 100);
    @include mobile(){
      margin-top: 200px;
    }

    .o-image{
      height: fit-content;
      width: 55%;
      @include mobile(){
        width: calc(100% - 44px);
      }
    }
    h3{
      display: block;
      position: absolute;
      height: fit-content;
      font-family: $thirdFont;
      font-size: 16vw;
      text-transform: uppercase;
      letter-spacing: -14px;
      transform: rotate(-90deg);
      color: #000;
      @include mobile(){
        opacity: 0;
      }
      &.l-title{
        margin-top: 100vh;
        margin-top: calc(var(--vh, 1vh) * 100);
        left: 10%;
        transform-origin: left;
        @include mobile(){
          margin-top: 100px;
          z-index: 99;
        }
      }
      &.r-title{
        margin-top: 50vh;
        margin-top: calc(var(--vh, 1vh) * 50);
        right: 10%;
        transform-origin: right;
        @include mobile(){
          margin-top: 0;
          bottom: 0;
        }
      }
    }
  }
  .full-image{
    margin-top: 100vh;
    margin-top: calc(var(--vh, 1vh) * 100);
    @include mobile(){
      margin-top: 200px;
    }
    /* img{
      transform: scale(1.2);
    } */
    &.vertical{
      margin-top: 0;
      @include mobile(){
        margin-top: 200px;
      }
    }
  }
  .big-title{
    display: flex;
    align-items: center;
    justify-content: center;

    h3{
      font-family: $primaryFont;
      font-size: 52vw;
      font-weight: 500;
      text-transform: uppercase;
      white-space: nowrap;
      color: #fe163e;
    }  
    h5{
      position: absolute;
      font-family: $primaryFont;
      font-size: 12vw;
      font-weight: 100;
      color: #1d2642;
      span{font-style: italic;}
    }
  }
  .t-image{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    @include mobile(){
      margin-top: 200px;
    }

    h3{
      display: block;
      font-size: 20vw;
      font-family: $thirdFont;
      text-align: center;
      &.f-title{
        margin-bottom: -15vh;
        margin-bottom: calc(var(--vh, 1vh) * -15);
        letter-spacing: -27px;
        @include mobile(){
          margin-bottom: 0;
          position: relative;
          z-index: 99;
          letter-spacing: -10px;
        }
      }
      &.l-title{
        position: relative;
        margin-top: -15vh;
        margin-top: calc(var(--vh, 1vh) * -15);
        letter-spacing: -44px;
        z-index: -1;
        @include mobile(){
          margin-top: 0;
          position: relative;
          z-index: 99;
          letter-spacing: -10px;
        }
      }
    }
    p{
      display: block;
      @include font(10, 150);
      font-family: $secondaryFont;
      text-transform: uppercase;
      letter-spacing: 16.67px;
      text-align: center;
      font-weight: 700;
    }
    img{
      max-width: 50vw;
      margin: 0 auto;
    }
  }
  .trail-end{
    position: relative;
    z-index: 1;
    padding-top: 50vh;
    padding-top: calc(var(--vh, 1vh) * 50);
    @include mobile(){
      margin-top: -100px;
    }

    .trail-c{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: -10vh;
      margin-bottom: calc(var(--vh, 1vh) * -10);
    }
    h4{
      font-family: $primaryFont;
      font-weight: 100;
      font-style: normal;
      text-align: center;
      margin: -15vh 0;
      margin: calc(var(--vh, 1vh) * -15) 0;
      @include mobile(){
        margin: -5vh 0;
        margin: calc(var(--vh, 1vh) * -5) 0;
      }

      span{
        font-style: italic;
        letter-spacing: -79.54px;
        white-space: nowrap;
        @include mobile(){
          letter-spacing: -30px;
        }
      }
      &.plain{
        position: relative;
        z-index: 99;
      }
    }
    .ending{
      position: absolute;
      max-width: 50vw;
      margin: 0 auto;
      @include mobile(){
        max-width: calc(100% - 48px);
      }
    }
  }
  .next-project{
    position: relative;
    padding-top: 30vh;
    padding-top: calc(var(--vh, 1vh) * 30);
    height: 80vh;
    height: calc(var(--vh, 1vh) * 80);
    @include mobile(){
      padding-top: 50vh;
      padding-top: calc(var(--vh, 1vh) * 50);
    }
    
    .signature{
      display: block;
      margin: 0 auto;
      width: auto;
      height: 24px;
      margin-bottom: 20vh;
      margin-bottom: calc(var(--vh, 1vh) * 20);
      @include mobile(){
        margin-bottom: 10vh;
        margin-bottom: calc(var(--vh, 1vh) * 10);
      }
    }
    a{
      width: 100%;
      position: absolute;
      margin-top: 2.5vh;
      margin-top: calc(var(--vh, 1vh) * 2.5);
      display: block;
      font-family: $primaryFont;
      font-weight: 100;
      font-size: 18.056vw;
      letter-spacing: -7px;
      text-align: center;
      &:hover{
        .n-b, .c-s{
          bottom: -15vh;
          transition: all .75s cubic-bezier(.6,0,.4,1);
        }
      }
      span{font-style: italic;}
    }
    .n-b{
      position: absolute;
      transform: translate(150%, 0);
      max-width: 25vw;
      bottom: -50vh;
      z-index: 99;
      mix-blend-mode: screen;
      transition: all .5s cubic-bezier(.6,0,.4,1);
      @include mobile(){
        opacity: 0;
        display: none;
      }
    }
    .c-s{
      position: absolute;
      background: #000;
      width: 22.2vw;
      bottom: -50vh;
      transform: translate(175.8%, 0);
      z-index: 94;
      transition: all .5s cubic-bezier(.6,0,.4,1);
      @include mobile(){
        opacity: 0;
        display: none;
      }
      &::before{
        content: "";
        display: block;
        padding-top: 144.3%;
      }
    }
  }
  .o-image_wrapper{
    position: relative;
    overflow: hidden;
  }

  /*
    Custom for projects
  */
  /* The Veil */
  &.the-veil{
    background-color: #fff;
    .lazy{background: #e1cab7;}
    .c-layer{background: #e1cab7;}
    .l-item{color: #014d4d;}
    .t-head h2{
      color: #9a785c; letter-spacing: -18px;
      @include mobile(){letter-spacing: -10px;}
      &.italic{@include mobile(){letter-spacing: -5px;}}
    }
    .t-hea
    .t-head .italic{letter-spacing: -10px;}
    .b-date{color: #9a785c;}
    .c-wrapper{
      h3{color: #014d4d;}
      .author{color: #e1cab7;}
      .dot{color: #9a785c;}
    }
    .list-photos h3{
      font-size: 33vw;
      font-weight: 100;
      color: #f3eae2;
      @include mobile(){
        font-size: 44vw;
      }
    }
    .s-image{
      .l-title{color: #e1cab7;}
      .r-title{color: #014d4d;}
    }
    .trail-end{
      background: #e1cab7;
      h4.plain{color: #9a785c;}
      h4.outline{color: #9a785c;}
      h4{
        font-size: 31vw;
        letter-spacing: -35px;
        white-space: nowrap;
        @include mobile(){
          font-size: 35vw;
          letter-spacing: -10px;
        }
        span{letter-spacing: -10px;}
      }
    }
    .next-project{
      background: #e1cab7;
      //go to https://codepen.io/sosuke/pen/Pjoqqp
      .signature{filter: grayscale(1);}
      a{color: #F4E4D7;}
      .c-s{transform: translate(175.7%, 0);}
    }
  }
  /* Milano */
  &.milano{
    background-color: #fac8c1;
    .lazy{background: #ed423a;}
    .c-layer{background: #ed423a;}
    .l-item{color: #fff;}
    .t-head h2{color: #000; letter-spacing: -6px;}
    .t-head .italic{letter-spacing: -10px;}
    .b-date{color: #000;}
    .c-wrapper{
      h3{color: #ed423a;}
      .author{color: #0f0f0f;}
      .dot{color: #fff;}
    }
    .list-photos h3{
      font-size: 38vw;
      font-weight: 100;
      color: #ed423a;
      @include mobile(){
        font-size: 44vw;
      }
    }
    .o-image_mask{background: #fac8c1;}
    .trail-end{
      background: #ed423a;
      h4.plain{color: #000000;}
      h4.outline{-webkit-text-stroke: 1px #000; color: transparent;}
      h4{
        font-size: 33vw;
        letter-spacing: -10px;
        @include mobile(){
          font-size: 35vw;
          letter-spacing: -10px;
        }
        span{letter-spacing: -10px;}
      }
    }
    .next-project{
      background: #fac8c1;
      //go to https://codepen.io/sosuke/pen/Pjoqqp
      .signature{filter: grayscale(1);}
      a{color: #fff;}
    }
  }
  /* London */
  &.london{
    background-color: #fff;
    .lazy{background: #e1dcd6;}
    .c-layer{background: #e1dcd6;}
    .l-item{color: #d4382c;}
    .t-head h2{color: #fff; letter-spacing: -6px;}
    .t-head .italic{letter-spacing: -10px;}
    .b-date{color: #000;}
    .c-wrapper{
      h3{color: #000;}
      .author{color: #d4382c;}
      .dot{color: #e1dcd6;}
    }
    .list-photos h3{
      font-size: 34vw;
      font-weight: 100;
      color: #e1dcd6;
      @include mobile(){
        font-size: 44vw;
      }
    }
    .o-image_mask{background: #fff;}
    .trail-end{
      background: #e1dcd6;
      h4.plain{color: #fff;}
      h4.outline{-webkit-text-stroke: 1px #fff; color: transparent;}
      h4{
        font-size: 33vw;
        letter-spacing: -10px;
        @include mobile(){
          font-size: 35vw;
          letter-spacing: -10px;
        }
        span{letter-spacing: -10px;}
      }
    }
    .next-project{
      background: #e1dcd6;
      //go to https://codepen.io/sosuke/pen/Pjoqqp
      .signature{filter: contrast(0.1) brightness(100);}
      a{color: #c9c2ba;}
      .c-s{width: 22.15vw; transform: translate(175.6%, 0);&::before{padding-top: 144.3%;}}
    }
  }
  /* Line's */
  &.lines{
    background-color: #fff;
    .lazy{background: #03ccbc;}
    .c-layer{background: #03ccbc;}
    .l-item{color: #000000;}
    .t-head h2{color: #fff; letter-spacing: -6px;}
    .t-head .italic{letter-spacing: -10px;}
    .b-date{color: #ff9152;}
    .c-wrapper{
      h3{color: #03ccbc;}
      .author{color: #000000;}
      .dot{color: #ff9152;}
    }
    .list-photos h3{
      font-size: 46vw;
      font-weight: 100;
      color: #03ccbc;
      @include mobile(){
        font-size: 44vw;
      }
    }
    .o-image_mask{background: #fff;}
    .trail-end{
      background: #d9f8f5;
      .ending{max-width: 88.889vw;}
      h4.plain{color: #fff;}
      h4.outline{color: #fff;}
      h4{
        font-size: 26vw;
        letter-spacing: -10px;
        @include mobile(){
          font-size: 35vw;
          letter-spacing: -10px;
        }
        span{letter-spacing: -10px;}
      }
    }
    .next-project{
      background: #d9f8f5;
      //go to https://codepen.io/sosuke/pen/Pjoqqp
      .signature{filter: brightness(1);}
      a{color: #03ccbc;}
    }
  }
  /* Gotham */
  &.gotham{
    background-color: #fff;
    .lazy{background: #05282c;}
    .c-layer{background: #05282c;}
    .l-item{color: #ff350c;}
    .t-head h2{color: #e0dbd5; letter-spacing: -6px;}
    .t-head .italic{letter-spacing: -10px;}
    .b-date{color: #ff350c;}
    .c-wrapper{
      h3{color: #06434b;}
      .author{color: #ff350c;}
      .dot{color: #ed423a;}
    }
    .list-photos h3{
      font-size: 30vw;
      font-weight: 100;
      color: #e0dbd5;
      @include mobile(){
        font-size: 44vw;
      }
    }
    .o-image_mask{background: #fff;}
    .trail-end{
      background: #05282c;
      h4.plain{color: #ff350c;}
      h4.outline{color: #06434b;}
      h4 span{
        font-size: 30vw;
        letter-spacing: -10px;
        @include mobile(){
          font-size: 35vw;
        }
      }
    }
    .next-project{
      background: #05282c;
      //go to https://codepen.io/sosuke/pen/Pjoqqp
      .signature{filter: invert(100%) sepia(100%) saturate(20%) hue-rotate(289deg) brightness(104%) contrast(106%);}
      a{color: #06434b;}
    }
  }
  /* Cooper */
  &.cooper{
    background-color: #fff;
    .lazy{background: #101529;}
    .c-layer{background: #101529;}
    .l-item{color: #feed00;}
    .t-head h2{color: #fe163e; letter-spacing: -6px;}
    .t-head .italic{letter-spacing: -10px;}
    .b-date{color: #fe163e;}
    .c-wrapper{
      h3{color: #1d2642;}
      .author{color: #fe163e;}
      .dot{color: #feed00;}
    }
    .list-photos h3{
      font-size: 34vw;
      color: #fe163e;
      @include mobile(){
        font-size: 44vw;
      }
    }
    .o-image_mask{background: #fff;}
    .s-image{
      .l-title{color: #cccccc;}
      .r-title{color: #cccccc;}
    }
    .t-image{
      .f-title{color: #cccccc;}
      .l-title{color: #cccccc;}
      p{color: #700a08;}
    }
    .l-f-i img{max-width: 88.889vw; margin: 0 auto;}
    .trail-end{
      background: #101529;
      h4.plain{color: #fe163e;}
      h4.outline{color: #fe163e;}
      h4{
        font-weight: 100;
        font-size: 30vw;
        letter-spacing: -10px;
        margin: -25vh 0;
        margin: calc(var(--vh, 1vh) * -25) 0;
        @include mobile(){
          font-size: 35vw;
          letter-spacing: -10px;
        }
      }
    }
    .next-project{
      background: #101529;
      //go to https://codepen.io/sosuke/pen/Pjoqqp
      .signature{filter: invert(80%) sepia(75%) saturate(715%) hue-rotate(358deg) brightness(102%) contrast(104%);}
      a{color: #333c5b;}
    }
  }
  /* Street */
  &.street{
    background-color: #e6e6e6;
    .lazy{background: #0f0f0f;}
    .c-layer{background: #0f0f0f;}
    .l-item{color: #808080;}
    .t-head h2{color: #e6e6e6; letter-spacing: 0;}
    .t-head .italic{letter-spacing: -10px;}
    .b-date{color: #333333;}
    .c-wrapper{
      h3{color: #333333;}
      .author{color: #999999;}
      .dot{color: #bfbfbf;}
    }
    .list-photos h3{
      font-size: 28vw;
      color: #fff;
      @include mobile(){
        font-size: 36vw;
      }
    }
    .o-image_mask{background: #e6e6e6;}
    .s-image{
      .l-title{color: #cccccc;}
      .r-title{color: #cccccc;}
    }
    .t-image{
      .f-title{color: #cccccc;}
      .l-title{color: #cccccc;}
      p{color: #700a08;}
    }
    .trail-end{
      background: #0f0f0f;
      h4.plain{color: #000000;}
      h4.outline{color: #000000;}
      h4 span{
        font-weight: 100;
        font-size: 30vw;
        letter-spacing: -10px;
        @include mobile(){
          font-size: 35vw;
          letter-spacing: -10px;
        }
      }
    }
    .next-project{
      background: #0f0f0f;
      //go to https://codepen.io/sosuke/pen/Pjoqqp
      .signature{filter: invert(54%) sepia(76%) saturate(0%) hue-rotate(213deg) brightness(97%) contrast(85%);}
      a{color: #242424;}
      .c-s{transform: translate(175%, 0);}
    }
  }
  /* Oculus */
  &.oculus{
    background-color: #fff;
    .lazy{background: #f0cdb9;}
    .c-layer{background: #f0cdb9;}
    .l-item{color: #700a08;}
    .t-head h2{color: #0f0f0f; letter-spacing: 0;}
    .t-head .italic{letter-spacing: -10px;}
    .b-date{color: #700a08;}
    .c-wrapper{
      h3{color: #f0cdb9;}
      .author{color: #700a08;}
      .dot{color: #700a08;}
    }
    .list-photos h3{
      font-size: 28vw;
      color: #faf0ea;
      @include mobile(){
        font-size: 36vw;
      }
    }
    .o-image_mask{background: #fff;}
    .s-image{
      .l-title{color: #f0cdb9;}
      .r-title{color: #700a08;}
    }
    .t-image{
      .f-title{color: #f0cdb9;}
      .l-title{color: #700a08;}
      p{color: #700a08;}
    }
    .trail-end{
      background: #f0cdb9;
      h4.plain{color: #700a08;}
      h4.outline{-webkit-text-stroke: 1px #fff; color: transparent;}
      h4{
        font-weight: 100;
        font-size: 30vw;
        letter-spacing: 20px;
        @include mobile(){
          font-size: 35vw;
          letter-spacing: -10px;
        }
      }
    }
    .next-project{
      background: #f0cdb9;
      //go to https://codepen.io/sosuke/pen/Pjoqqp
      .signature{filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(135deg) brightness(106%) contrast(104%);}
      a{color: #ffe7da;}
      .c-s{width: 22.17vw; transform: translate(176.2%, 0);&::before{padding-top: 144.4%;}}
    }
  }
  /* Lifestyle 02 */
  &.lifestyle-02{
    background-color: #fff4ee;
    .lazy{background: #90927a;}
    .c-layer{background: #90927a;}
    .l-item{color: #32191f;}
    .t-head h2{color: #ffe7da; letter-spacing: 0;}
    .t-head .italic{letter-spacing: -10px;}
    .b-date{color: #e94f37;}
    .c-wrapper{
      h3{color: #90927a;}
      .author{color: #e94f37;}
      .dot{color: #e94f37;}
    }
    .list-photos h3{
      font-size: 28vw;
      color: $white;
      @include mobile(){
        font-size: 36vw;
      }
    }
    .o-image_mask{background: #fff4ee;}
    .s-image{
      .l-title{color: #90927a;}
      .r-title{color: #32191f;}
    }
    .t-image{
      .f-title{color: #90927a;}
      .l-title{color: #32191f; font-size: 25vw;}
      p{color: #ff3930;}
    }
    .trail-end{
      background: #90927a;
      h4.plain{color: #32191f;}
      h4.outline{-webkit-text-stroke: 1px #fff; color: transparent;}
      h4{
        font-weight: 100;
        font-size: 30vw;
        letter-spacing: -10px;
        @include mobile(){
          font-size: 35vw;
          letter-spacing: -10px;
        }
      }
    }
    .next-project{
      background: #90927a;
      //go to https://codepen.io/sosuke/pen/Pjoqqp
      .signature{filter: invert(93%) sepia(11%) saturate(118%) hue-rotate(353deg) brightness(97%) contrast(85%);}
      a{color: #ffe7da;}
      .c-s{transform: translate(175.1%, 0);&::before{padding-top: 144.2%;}}
    }
  }
  /* Lifestyle 01 */
  &.lifestyle-01{
    background-color: #ffe7da;
    .lazy{background: #30082a;}
    .c-layer{background: #30082a;}
    .l-item{color: #ff3930;}
    .t-head h2{color: #ffdbc5; letter-spacing: 0;}
    .t-head .italic{letter-spacing: -10px;}
    .b-date{color: #ff3930;}
    .c-wrapper{
      h3{color: #5e1353;}
      .author{color: #ff3930;}
      .dot{color: #ff3930;}
    }
    .list-photos h3{
      font-size: 28vw;
      color: $white;
      @include mobile(){
        font-size: 36vw;
      }
    }
    .o-image_mask{background: #ffe7da;}
    .s-image{
      .l-title{color: #5e1353;}
      .r-title{color: #5e1353;}
    }
    .t-image{
      .f-title{color: #5e1353;}
      .l-title{color: #5e1353;}
      p{color: #f0cdb9;}
    }
    .trail-end{
      background: #30082a;
      h4.plain{color: #ff3930;}
      h4.outline{-webkit-text-stroke: 1px #ff3930; color: transparent;}
      h4 span{
        font-weight: 100;
        font-size: 30vw;
        letter-spacing: 0px;
        @include mobile(){
          font-size: 35vw;
          letter-spacing: -10px;
        }
      }
    }
    .next-project{
      background: #30082a;
      //go to https://codepen.io/sosuke/pen/Pjoqqp
      .signature{filter: invert(88%) sepia(20%) saturate(624%) hue-rotate(316deg) brightness(102%) contrast(101%);}
      a{color: #4c2446;}
    }
  }
  /* New york */
  &.new-york{
    background-color: #e6e6e6;
    .lazy{background: #171717;}
    .c-layer{background: #171717;}
    .l-item{color: #808080;}
    .t-head h2{color: #e6e6e6;}
    .t-head .italic{letter-spacing: -10px;}
    .b-date{color: #171717;}
    .c-wrapper{
      h3{color: #333333;}
      .author{color: #999999;}
      .dot{color: #bfbfbf;}
    }
    .list-photos h3{
      font-size: 28vw;
      color: $white;
      @include mobile(){
        font-size: 36vw;
      }
    }
    .o-image_mask{background: #e6e6e6;}
    .s-image{
      .l-title{color: #cccccc;}
      .r-title{color: #cccccc;}
    }
    .t-image{
      .f-title{color: #cccccc;}
      .l-title{color: #cccccc;}
      p{color: #f0cdb9;}
    }
    .trail-end{
      background: #0f0f0f;
      h4.plain{color: $white;}
      h4.outline{color: #000;}
      h4 span{
        font-weight: 100;
        font-size: 30vw;
        letter-spacing: 0px;
        @include mobile(){
          font-size: 35vw;
          letter-spacing: -10px;
        }
      }
    }
    .next-project{
      background: #0f0f0f;
      //go to https://codepen.io/sosuke/pen/Pjoqqp
      .signature{filter: invert(61%) sepia(4%) saturate(10%) hue-rotate(314deg) brightness(98%) contrast(86%);}
      a{color: #e1dcd6;}
    }
  }
  /* Aerial */
  &.aerial{
    background-color: #e6e6e6;
    .lazy{background: #9f0125;}
    .c-layer{background: #9f0125;}
    .l-item{color: #ff4d67;}
    .t-head h2{color: #e6e6e6;}
    .t-head .italic{letter-spacing: -10px;}
    .b-date{color: #9f0125;}
    .c-wrapper{
      h3{color: #9f0125;}
      .author{color: #999999;}
      .dot{color: #333333;}
    }
    .list-photos h3{
      font-size: 28vw;
      color: #9f0125;
      @include mobile(){
        font-size: 36vw;
      }
    }
    .o-image_mask{background: #e6e6e6;}
    .s-image{
      .l-title{color: #9f0125;}
      .r-title{color: #333333;}
    }
    .t-image{
      .f-title{color: #9f0125;}
      .l-title{color: #333333;}
      p{color: #f0cdb9;}
    }
    .trail-end{
      background: #9f0024;
      h4.plain{color: #e6e6e6;}
      h4.outline{color: #d0002f;}
      h4 span{
        font-weight: 100;
        font-size: 30vw;
        letter-spacing: 0px;
        @include mobile(){
          font-size: 35vw;
          letter-spacing: -10px;
        }
      }
    }
    .next-project{
      background: #9f0024;
      //go to https://codepen.io/sosuke/pen/Pjoqqp
      .signature{filter: invert(0%) sepia(89%) saturate(7457%) hue-rotate(326deg) brightness(90%) contrast(109%);}
      a{color: #ff4d67;}
      .c-s{transform: translate(175%, 0);&::before{padding-top: 144.5%;}}
    }
  }
  /* Vessel */
  &.vessel{
    background-color: $white;
    .lazy{background: #00162e;}
    .l-item{color: #70afcb;}
    .c-layer{background: #00162e;}
    .t-head h2{color: $orange;}
    .t-head .italic{letter-spacing: -42px; @include mobile(){letter-spacing: -10px;}}
    .b-date{color: $orange;}
    .c-wrapper{
      h3{color: #dba294;}
      .author{color: $bg;}
      .dot{color: $bg;}
    }
    .list-photos h3{
      font-size: 28vw;
      color: #f6e8e4;
      @include mobile(){
        font-size: 36vw;
      }
    }
    .o-image_mask{background: $white;}
    .s-image{
      .l-title{color: #DBA294;}
      .r-title{color: #00162E;}
    }
    .t-image{
      .f-title{color: #dba294;}
      .l-title{color: #00162e; font-size: 25vw;}
      p{color: #f0cdb9;}
    }
    .trail-end{
      background: #00162e;
      h4.plain{color: #ff775c;}
      h4.outline{-webkit-text-stroke: 1px #ff775c; color: transparent;}
      h4{
        font-size: 27.5vw;
        letter-spacing: -23.5px;
        @include mobile(){
          font-size: 35vw;
          letter-spacing: -10px;
        }
      }
    }
    .next-project{
      background: #00162e;
      //go to https://codepen.io/sosuke/pen/Pjoqqp
      .signature{filter: invert(70%) sepia(43%) saturate(341%) hue-rotate(153deg) brightness(85%) contrast(94%);}
      a{color: #e1dcd6;}
      .c-s{transform: translate(175.1%, 0);}
    }
  }
}