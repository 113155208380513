@font-face {
  font-family: 'Ekster';
  font-weight: 700;
  src: url("fonts/Ekster-Bold.otf") format("truetype");
}
@font-face {
  font-family: 'Ekster';
  font-weight: 400;
  src: url("fonts/Ekster-Regular.otf") format("truetype");
}
@font-face {
  font-family: 'Roxborough';
  font-weight: 700;
  src: url("fonts/RoxboroughCF-Bold.otf") format("truetype");
}
@font-face {
  font-family: 'Roxborough';
  font-weight: 500;
  src: url("fonts/RoxboroughCF-Medium.otf") format("truetype");
}
@font-face {
  font-family: 'Roxborough';
  font-weight: 300;
  font-style: normal;
  src: url("fonts/RoxboroughCF-Thin.otf") format("truetype");
}
@font-face {
  font-family: 'Roxborough';
  font-weight: 300;
  font-style: italic;
  src: url("fonts/RoxboroughCF-ThinItalic.otf") format("truetype");
}
@font-face {
  font-family: 'Wrangler';
  font-weight: 300;
  src: url("fonts/Wrangler.woff2") format("woff2"),
       url("fonts/Wrangler.woff") format("woff");
}