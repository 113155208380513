.header{
  z-index: 100;
  position: fixed;
  left: 0;
  top: 44px;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  mix-blend-mode: difference;

  nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: $secondaryFont;
    font-weight: 700;

    a{
      display: block;
      @include font(15, 22);
      color: $grey;
      transition: all .5s;
      &:hover{
        color: $white;
        transition: all .5s;
      }
      &.logo{
       color: $white;
       font-family: $primaryFont; 
       @include font(28, 36);
       mix-blend-mode: difference;
      }
      &:first-child{
        margin-left: 30px;
      }
      &:last-child{
        margin-right: 30px;
      }
    }
  }
}