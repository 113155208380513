.images-gallery{
  position: relative;
  z-index: 99;

  .cover-item{
    transition: opacity .5s;
    /* &:nth-child(odd){
      top: 50vh;
      top: calc(var(--vh, 1vh) * 50);
    } */

    &:hover{
      opacity: .75;
      transition: opacity .5s;
    }
  }
}