// Colors
$white: #fff;
$black: #000;
$bg: #0F0F0F;
$grey: #666666;
$lightGrey: #f2f2f2;
$orange: #ff775c;

// Fonts
$primaryFont: "Roxborough", serif;
$secondaryFont: "Ekster", sans-serif;
$thirdFont: "Wrangler", sans-serif;