.time{
  z-index: 100;
  position: fixed;
  left: 0;
  bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-family: $secondaryFont;
  font-weight: 700;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  span{
    display: block;
    @include font(15, 22);
    color: $grey;

    &:first-child{
      margin-left: 30px;
    }
    &:last-child{
      margin-right: 30px;
    }
  }
  .time-system{
    text-transform: lowercase;
  }
}