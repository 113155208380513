#loading {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  @include mobile(){
    display: none;
    opacity: 0;
  }

  .o-hidden{
    position: relative;
    width: 100%;
    height: 70px;
  }
  span{
    display: flex;
    position: absolute;
    justify-content: flex-end;
    right: -20px;
    text-align: right;
    font-family: $primaryFont;
    @include font(50, 62);
    font-weight: 100;
    color: #e6e6e6;
    z-index: 99;
  }
}

.intro{
  position: relative;
  background-color: $bg;
  color: $white;
  width: 100%;
  height: 100%;
  z-index: 99;

  &-bg{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.c-intro{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  h2{
    display: block;
    @include font(20, 28);
    font-family: $secondaryFont;
    font-weight: 500;
  }
}

.c-trail{
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
  overflow: hidden;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;

  .content__img {
    position: absolute;
    top: 0;
    left: 0;
    will-change: transform;
    height: 50vh;
    object-fit: cover;
    width: auto;
    opacity: 0;

    &--full{
      width: 100%;
      height: 100%;
      background-size: cover;
    }
  }
}