body{
  background: $bg;
  color: $white;
  overflow: hidden;
  @include tablet-down(){
    overflow: auto;
  }
}  

.p-fixed{
  position: fixed;
  overflow: hidden;
}

a{
  display: block;

  &:hover{
    .c-l{
      transform: rotate(-10deg);
    }
    .c-r{
      transform: rotate(-10deg);
    }
  }
  span{
    display: inline-block; 
  }
  .c-l{
    transform-origin: bottom left;
    transition: transform 0.4s cubic-bezier(0.7, 0, 0.3, 1), -webkit-transform 0.4s cubic-bezier(0.7, 0, 0.3, 1);
  }
  .c-r{
    transform-origin: bottom right;
    transition: transform 0.4s cubic-bezier(0.7, 0, 0.3, 1), -webkit-transform 0.4s cubic-bezier(0.7, 0, 0.3, 1);
  }
}

.link{
  text-decoration: none;
  position: relative;

  &::after {
    content: "";
    border-bottom: solid 1px #0f0f0f;
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
  }

  &:hover {
    &::after {
      transform-origin: left;
      transform: scaleX(1);
    }
  }

  @keyframes underline {
    from {
      transform: scaleX(0);
    }
    to {
      transform: scaleX(1);
    }
  }
}

.mt-25{margin-top: 25vh; margin-top: calc(var(--vh, 1vh) * 25);}
.mt-50{margin-top: 50vh; margin-top: calc(var(--vh, 1vh) * 50);}
.m80{max-width: 88.889vw; margin: 0 auto;}
.italic{font-style: italic;}
main[data-barba]{height: 100%;}

canvas{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.o-hidden{ overflow: hidden; }

//Reset + default
// Default
* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}
html, body{
  width: 100%;
  height: 100%;
}
html, body{
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
}
a,body,div,footer,h1,h2,h3,header,html,img,li,nav,p,span,table,ul{margin:0;padding:0;border:0;font:inherit;font-family:inherit;font-size:100%}
li,ul{list-style:none}
a{color:inherit;text-decoration:none}
img{width:100%;height:auto}
button{border: none;margin: 0;padding: 0;width: auto; outline: 0;overflow: visible;background: transparent;color: inherit;font: inherit;line-height: normal;-webkit-appearance: none; &::-moz-focus-inner {border: 0; padding: 0;} }